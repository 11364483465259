function toggleFacet(event) {

   const facetContainer = document.querySelector(event.getAttribute('data-target-id'));

   const facetContent = facetContainer.querySelector('.magellan-facet-content-wrapper');

   const facetIcon = event.querySelector('.magellan-facet-icon');

   if (facetIcon) {
      facetIcon.classList.toggle('rotate');
   }

   if (facetContent) {
      facetContent.classList.toggle('closed');
   }

}

function toggleFacetChildren(element) {
   const childrenId = element.getAttribute('data-term-children-id')

   if(!childrenId) return;

   const checked = element.checked
   childrenId.split(',').forEach(id => {
      const facet = document.querySelector(`input[data-term-id='${id}']`);
      if (facet && facet.checked !== checked) {
         facet.click();
      }
   })

}

Liferay.ThemeDisplay = Liferay.ThemeDisplay || {};
Liferay.ThemeDisplay.toggleFacet = toggleFacet;
Liferay.ThemeDisplay.toggleFacetChildren = toggleFacetChildren;